<template>
  <ResolveComplain
    :display="showResolve"
    @closeResolveComplain="closeResolve"
  ></ResolveComplain>
  <Card>
    <template #content v-if="loading || complaints.length > 0">
      <div class="grid">
        <div class="col-4">
          <h4>{{ $t("complaints.title") }}</h4>
        </div>
        <div class="col-8 text-right">
          <Button v-if="!isMobile" :label="$t('global.export')" @click="exportCSV"></Button>
        </div>
      </div>
      <DataTable
        :value="complaints"
        :paginator="true"
        :rows="10"
        :loading="loading"
        :filters="filters"
        dataKey="id"
        ref="tableRatings"
        showGridlines
        class="p-datatable-ratings"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        :currentPageReportTemplate="$tableString"
        removableSort
        sortField="date"
        :sortOrder="-1"
      >
        <template #header>
          <div>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('global.search')"
              />
            </span>
          </div>
        </template>
        <template #empty> {{ $t("complaints.noComplaints") }} </template>
        <Column
          field="complaintId"
          :header="$t('complaints.complaintId')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.id }}
          </template>
        </Column>
        <Column
          field="date"
          :header="$t('complaints.complaintDate')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.date }}
          </template>
        </Column>
        <Column
          field="company"
          :header="$t('complaints.company')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.company }}
          </template>
        </Column>
        <Column field="refId" :header="$t('complaints.refId')" :sortable="true">
          <template #body="{ data }">
            {{ data.refId }}
          </template>
        </Column>
        <Column
          field="complainant"
          :header="$t('complaints.complainant')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.complainant }}
          </template>
        </Column>
        <Column
          field="messageDate"
          :header="$t('complaints.messageDate')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.messageDate }}
          </template>
        </Column>
        <Column field="status" :header="$t('global.status')" :sortable="true">
          <template #body="{ data }">
            {{ translatedStatus(data.status) }}
          </template>
        </Column>
        <Column
          field="actions"
          :header="$t('global.actions')"
          :exportable="false"
        >
          <template>
            <Button placeholder="Resolve" @click="openResolve"></Button>
          </template>
        </Column>
      </DataTable>
    </template>
    <template #content v-else-if="!loading">
      <NoTableData :type="'complaints'"></NoTableData>
    </template>
  </Card>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { mapGetters } from "vuex";
import ResolveComplain from "./ResolveComplain.vue";
import NoTableData from "../NoTableData.vue";

export default {
  name: "Complaints",
  components: {
    ResolveComplain,
    NoTableData,
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      loading: false,
      showResolve: false,
      complaints: [],
    };
  },
  computed: {
    ...mapGetters(["translatedStatus","isMobile"]),
  },
  async mounted() {
    this.loading = true;
    //     await this.$store.dispatch("cases", {});
    this.loading = false;
    this.$action("complaints-page-view");
  },
  methods: {
    exportCSV() {
      this.$refs.tableRatings.exportCSV();
      this.$action("export-complaints");
    },
    openResolve() {
      this.showResolve = true;
    },
    closeResolve() {
      this.showResolve = false;
    },
  },
};
</script>
