<template>
  <Dialog
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :closable="false"
    :style="{ width: '50vw' }"
  >
    <p class="font-bold text-base mb-1">Root cause analysis</p>
    <p class="font-light mb-1">
      Please compose your analysis below and send to those involved
    </p>
    <Editor v-model="value" editorStyle="height: 20rem" />
    <div class="grid grid-noguuter mt-3">
      <div class="col-10">
        <div class="p-fluid h-4">
          <InputText></InputText>
        </div>
      </div>
      <div class="col-2">
        <div class="p-fluid">
          <Button label="Email"></Button>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        :label="$t('global.submit')"
        class="p-button-success"
        iconPos="right"
        :loading="loading"
      />
      <Button
        :label="$t('global.save')"
        class="p-button-warning"
        iconPos="right"
        :loading="loading"
      />
      <Button
        :label="$t('global.close')"
        @click="close"
        class="p-button-danger"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "Resolve complain",
  props: ["display", "caseValue"],
  emits: ["closeResolveComplain"],
  data() {
    return {
      viewDialog: false,
      loading: false,
      rating: null,
      ratingComments: "",
      value: "",
    };
  },
  watch: {
    display(val) {
      this.viewDialog = val;
    },
    async viewDialog(val) {
      if (val == false) {
        this.close();
      }
    },
  },
  computed: {
    case() {
      return {
        ...this.caseValue,
      };
    },
  },
  methods: {
    close(success = false) {
      this.$emit("closeResolveComplain", success);
    },
  },
};
</script>
